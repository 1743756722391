<template>
    <div>
        <b-button-toolbar class="mb-3">
            <b-button-group class="ml-auto">
                <b-button to="../list">
                    <b-icon icon="list"/>
                    Список
                </b-button>
            </b-button-group>
        </b-button-toolbar>
        <b-overlay :show="!item">
            <b-table
                :stacked="true"
                :items="rows"
                :fields="fields"
            >
                <template #cell(contract_id)="row">
                    <router-link :to="{name:'contracts.view', params:{id:row.item.contract_id}}">
                        {{ row.item.contract_id }}
                    </router-link>
                </template>
                <template #cell(status)="row">
                    {{ row.item.status }}
                    <b-button
                        v-if="row.item.status === 'AUTHORIZED' && row.item.payment_id"
                        @click="refund"
                    >
                        <b-icon icon="trash"/>
                        Вернуть
                    </b-button>
                    <b-button
                        v-if="row.item.status === 'DRAFT'"
                        @click="charge"
                    >
                        <b-icon icon="check-lg"/>
                        Выполнить
                    </b-button>
                </template>
            </b-table>
        </b-overlay>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fields: [
                {
                    key: "id",
                    label: "Id",
                },
                {
                    key: "contract_id",
                    label: "Контракт",
                },
                {
                    key: "planned_at",
                    label: "Запланирован",
                    formatter(value) {
                        return window.formatDatetime(value);
                    },
                },
                {
                    key: "status",
                    label: "Статус",
                },
                {
                    key: "order_id",
                    label: "Tinkoff order_id",
                },
                {
                    key: "amount",
                    label: "Сумма",
                },
                {
                    key: "rejected_message",
                    label: "rejected_message",
                },
                {
                    key: "payment_id",
                    label: "payment_id",
                },
                {
                    key: "sms_error",
                    label: "sms_error",
                },
                {
                    key: "kkt_request",
                    label: "kkt_request",
                },
                {
                    key: "kkt_id",
                    label: "kkt_id",
                },
                {
                    key: "kkt_error",
                    label: "kkt_error",
                },
                {
                    key: "refund_answer",
                    label: "refund_answer",
                },
                {
                    key: "refund_at",
                    label: "refund_at",
                    formatter(value) {
                        return value ? window.formatDatetime(value) : "";
                    },
                },
                {
                    key: "created_at",
                    label: "Добавлен",
                    formatter(value) {
                        return window.formatDatetime(value);
                    },
                },
                {
                    key: "updated_at",
                    label: "Изменен",
                    formatter(value) {
                        return window.formatDatetime(value);
                    },
                },
            ],
            item: null,
        };
    },
    computed: {
        rows() {
            if (!this.item) {
                return [];
            }

            return [this.item];
        },
    },
    mounted() {
        this.refreshData();
    },
    methods: {
        refreshData() {
            const id = parseInt(this.$route.params.id);

            this.axios.get(`/admin_api/payments/${id}`).then(async ({data}) => {
                this.item = data;
            });
        },
        refund() {
            const id = parseInt(this.$route.params.id);

            this.axios.patch(`/admin_api/payments/${id}/refund`).then(async () => {
                this.$alertify.success("Возврат осуществлен");
                this.refreshData();
            }).catch(async ({response}) => {
                this.$alertify.error(response.data.message);
            });
        },
        charge() {
            const id = parseInt(this.$route.params.id);

            this.axios.patch(`/admin_api/payments/${id}/charge`).then(async () => {
                this.$alertify.success("Платеж осуществлен");
                this.refreshData();
            }).catch(async ({response}) => {
                this.$alertify.error(response.data.message);
            });
        },
    },
};
</script>
